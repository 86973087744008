
import presetAutoprefix from '@twind/preset-autoprefix';
import presetExt from '@twind/preset-ext';
import presetTailwind from '@twind/preset-tailwind';
import presetTypography from '@twind/preset-typography';

const mapDefaultTwSingleRemToPx = (v) => String(v).replace(/^([0-9\.]+)rem$/, (_0, _1) => `${_1 * 16}px`);

const mapDefaultTwRemToPx = (entries) => Object.fromEntries(
  Object.entries(entries)
    .map(e => [
      e[0],
      (Array.isArray(e[1]) ? e[1].map(mapDefaultTwSingleRemToPx) : mapDefaultTwSingleRemToPx(e[1]))
    ])
);

// @see https://tailwindcss.com/
// @see https://play.tailwindcss.com/
// @see https://twind.style/packages/@twind/core~TwindConfig
export default {
  preflight: false,
  hash: false,
  darkMode: 'class',
  presets: [
    presetAutoprefix(),
    presetExt(),
    presetTailwind(),
    presetTypography(),
  ],
  theme: {
    extend: {
      // @see node_modules/@twind/preset-tailwind/baseTheme.js
      borderRadius: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('borderRadius'))),
      }),
      borderWidth: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('borderWidth'))),
      }),
      fontSize: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('fontSize'))),
      }),
      height: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('height'))),
      }),
      lineHeight: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('lineHeight'))),
      }),
      maxHeight: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('spacing'))),
        ...(mapDefaultTwRemToPx(theme('maxHeight'))),
      }),
      maxWidth: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('spacing'))),
        ...(mapDefaultTwRemToPx(theme('maxWidth'))),
      }),
      minHeight: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('spacing'))),
        ...(mapDefaultTwRemToPx(theme('minHeight'))),
      }),
      minWidth: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('spacing'))),
        ...(mapDefaultTwRemToPx(theme('minWidth'))),
      }),
      spacing: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('spacing'))),
      }),
      width: ({theme}) => ({
        ...(mapDefaultTwRemToPx(theme('width'))),
      }),
    },
  },
  // @see https://twind.style/variants
  variants: [
    ['tw', (match) => 'html[lang] > body &'],
  ],
  ignorelist: [
    'active',
    'burger',
    'course',
    'item',
    'licenseTypeSelect2',
    'newPurchaseTeamsPricing',
    'pi2form',
    'planSelectPage',
    'slider',
    /--/,
    // /^(?!.*\-)[A-Z]/,
    /^col-/,
    /^course/,
    /^couse/,
    /^Footer/,
    /^icon-/,
    /^logo/,
    /^new/,
    /^pi/,
    /^pi2/,
    /^pi3/,
    /^pi4/,
    /^purchase/,
    /^RowMain/,
    /^u-/,
    /__/,
  ],
};
