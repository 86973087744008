
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { getProductsV4, repurchase } from './ajax';
import { toPrettyDollarsRound, getQueryAttribute, toHumanArray } from './helpers';
import LoadingCenter from './LoadingCenter';

const STRIPE_PUBKEY = __STRIPE_PUBLISHABLE_KEY__;
const stripePromise = loadStripe(STRIPE_PUBKEY);



class ConceptRepurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading', // 'loading', 'select', 'checkout', 'complete'
      products: [],
      stripeCheckoutSessionId: null,
    };
    this.checkout = this.checkout.bind(this);
  }
  async componentDidMount() {
    try {

      // load products
      let products = await getProductsV4();
      console.log(`[Repurchase] Fetched products.`, products);

      // check if 'complete' state
      let stage = getQueryAttribute(window.location.search, 's');
      if (stage === 'complete') {

        // empty cart
        let cart = window.cart;
        cart.emptyCart();

        // set 'complete' state
        this.setState({ stage: 'complete', products });
      }

      else {
        this.setState({ stage: 'select', products });
      }


    }
    catch(e) {
      console.error(`[Repurchase] Error in componentDidMount()`, e);
    }
  }
  async checkout() {
    try {
      let cart = window.cart;
      let { items } = cart.getCart();

      this.setState({
        stage: 'loading',
      });

      // construct body
      let body = {
        terms: true,
        productIds: items.map(i => i.id),
      };

      // make API call
      let response = await repurchase(body);
      console.log(`[Repurchase] Created Checkout Session.`, response);

      // move to checkout mode
      this.setState({
        stage: 'checkout',
        stripeCheckoutSessionId: response.stripeCheckoutSessionId,
        stripeCheckoutClientSecret: response.stripeCheckoutClientSecret,
      });
    }
    catch(e) {
      console.error(`[Repurchase] Error in checkout()`, e);
    }
  }
  render() {

    if (this.state.stage === 'loading') {
      return (
        <div>
          <LoadingCenter />
        </div>
      );
    }

    if (this.state.stage === 'select') {
      return (
        <div>

          <div style={{ border: '1px dashed #000' }}>
            <LandingCourseEmbed product={this.state.products[0]} />
            <LandingCourseEmbed product={this.state.products[4]} />
            <LandingCourseEmbed product={this.state.products[3]} />
            <LandingCourseEmbed product={this.state.products[1]} />
            <LandingCourseEmbed product={this.state.products[2]} />
            <LandingCourseEmbed product={this.state.products[5]} />
          </div>

          <br />

          <div style={{ border: '1px dashed #000' }}>
            <CartPage products={this.state.products} cart={window.cart} checkout={this.checkout} />
          </div>

          <br />

          <div style={{ border: '1px dashed #000' }}>
            <CartIcon cart={window.cart} />
          </div>

        </div>
      );
    }

    if (this.state.stage === 'checkout') {
      return (
        <div>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret: this.state.stripeCheckoutClientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      );
    }

    if (this.state.stage === 'complete') {

      let pids = getQueryAttribute(window.location.search, 'pids').split(',').map(id => parseInt(id));
      let purchasedProducts = this.state.products.filter(p => pids.indexOf(p.id) > -1);

      console.log('purchased products', purchasedProducts);

      return (
        <div>
          <h1>Purchase Complete!</h1>
          <div>cat</div>
          <p>
            We're processing your payment! {toHumanArray(purchasedProducts.map(p => p.name))} will be added to your course library in a few minutes.
          </p>
        </div>
      );
    }



    return (
      <div>
        repurchase component in unknown state
      </div>
    );
  }
}

export default ConceptRepurchase;




class LandingCourseEmbed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <h3>{this.props.product.name}</h3>
        <p>
          {this.props.product.description}
        </p>
        <p>
          <strong>{toPrettyDollarsRound(this.props.product.stripeProduct.price.amount)}</strong>
        </p>
        <a href={this.props.product.checkoutOverviewLink} target="_new">View Curriculum</a>
      </div>
    );
  }
}

// <CartPage products={products} cart={cart} />
class CartPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.cart) {
      console.error(`[CartPage] Constructor cannot mount cart.`);
    }
    // get cart items into state
    let { items, total } = this.props.cart.getCart();
    this.state = {
      items: items,
      total: total,
    };
    this.onCartChange = this.onCartChange.bind(this);
  }

  onCartChange(cart) {
    console.log(`[CartPage] Cart change happened.`, { items: cart.items, total: cart.total });
    // the cart changed
    this.setState({ items: cart.items, total: cart.total });
  }

  componentDidMount() {
    if (!this.props.cart) {
      console.error(`[CartPage] componentDidMount() cannot mount cart.`);
      this.props.cart.subscribe(this.onCartChange);
    }
    // subscribe to cart changes
    this.props.cart.subscribe(this.onCartChange);
  }

  componentWillUnmount() {
    // unsubscribe from cart changes
    this.props.cart.unsubscribe(this.onCartChange);
  }

  render() {

    let products = this.props.products;
    let productsRender = [];
    for (let product of products) {
      let isItemInCart = this.props.cart.isItemInCart(product);
      productsRender.push((
        <div key={product.id}>
          {product.name}
          &nbsp;
          <strong>{toPrettyDollarsRound(product.stripeProduct.price.amount)}</strong>
          &nbsp;
          {isItemInCart && 
            <span onClick={() => this.props.cart.remove(product)}>remove</span>
          }
          {!isItemInCart && 
            <span onClick={() => this.props.cart.add(product)}>add</span>
          }
        </div>
      ));
    }

    return (
      <div>
        {productsRender}
        <br />
        Total: <strong>{toPrettyDollarsRound(this.state.total)}</strong>
        <br />
        <button className='pi3button' onClick={this.props.checkout}>Checkout</button>
      </div>
    );
  }

}




// <CartIcon cart={cart} />
class CartIcon extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.cart) {
      console.error(`[CartIcon] Constructor cannot mount cart.`);
    }
    // get cart items into state
    let { items, total } = this.props.cart.getCart();
    this.state = {
      items: items,
      total: total,
    };
    this.onCartChange = this.onCartChange.bind(this);
  }

  onCartChange(cart) {
    console.log(`[CartIcon] Cart change happened.`, { items: cart.items, total: cart.total });
    // the cart changed
    this.setState({ items: cart.items, total: cart.total });
  }

  componentDidMount() {
    if (!this.props.cart) {
      console.error(`[CartIcon] componentDidMount() cannot mount cart.`);
      return;
    }
    // subscribe to cart changes
    this.props.cart.subscribe(this.onCartChange);
  }

  componentWillUnmount() {
    // unsubscribe from cart changes
    this.props.cart.unsubscribe(this.onCartChange);
  }

  render() {

    return (
      <div>
        {this.state.items && this.state.items.length > 0 &&
          <h1>{this.state.items.length}</h1>
        }
        {
          this.state.items.length === 0 &&
          <h1>∅</h1>
        }
      </div>
    );
  }

}


