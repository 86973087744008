
import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';

import './Twind.js';

import {routes} from '../../src/routes.js';

import Login from './Login';
import Menu from './Menu';
import SideMenu from './SideMenu';
import ContactUsPublic from './ContactUsPublic';
import NewPurchaseFlow from './NewPurchaseFlow';
import Join from './Join';

import LearnerCourseList from './LearnerCourseList';
import LearnerLesson from './LearnerLesson';
import LearnerQuiz from './LearnerQuiz';
import LearnerQuizPlus from './LearnerQuizPlus';
import {LearnerChatflow} from './LearnerChatflow';
import CourseCertificate from './CourseCertificate';
import CourseCertificates from './CourseCertificates';
import Profile from './Profile';
import License from './License';

import ContactUsLearners from './ContactUsLearners';

import ManagerDashboard from './ManagerDashboard';

import ConceptRepurchase from './ConceptRepurchase.js';
import ConceptCart from './ConceptCart';

import { unsetUser } from './session';
import { logout as ajax_logout, getLesson, getCourseProgress } from './ajax';



function loadMenu() {
  // console.log('[pi3] Hydrating Menu...');
  // hydrateRoot(document.getElementById('pi3menu'), <Menu />);

  let data = JSON.parse(window.menuData);

  console.log('[pi3] Rendering Menu...');
  let topMenuContainer = document.getElementById('pi3menu');
  if (topMenuContainer) {
    let topMenu = createRoot(topMenuContainer);
    topMenu.render(<Menu data={data} />);
  }
  else {
    console.log('[pi3] Menu container not found.');
  }

  console.log('[pi3] Rendering SideMenu...');
  let sideMenuContainer = document.getElementById('pi3sideMenu');
  if (sideMenuContainer) {
    let sideMenu = createRoot(sideMenuContainer);
    sideMenu.render(<SideMenu data={data} />);
  }
  else {
    console.log('[pi3] SideMenu container not found.');
  }

}

async function loadCourseList() {
  try {
    console.log('[pi3] Loading LearnerCourseList...');


    // render LearnerLesson
    let container = document.getElementById('pi3courseList');
    let reader = createRoot(container);
    reader.render(<LearnerCourseList />);

  }
  catch(e) {
    console.error('[pi3] Error in loadCourseList().', e);
  }
}

async function loadLesson(lessonId) {
  try {
    console.log('[pi3] Loading LearnerLesson...', { lessonId });

    // get lesson/quiz data from server
    let lessonData = await getLesson(lessonId);
    console.log('[pi3] Fetched quiz data.', lessonData);

    // render LearnerLesson
    let container = document.getElementById('pi3lessonReader');
    let reader = createRoot(container);
    reader.render(<LearnerLesson lesson={lessonData} />);
  }
  catch(e) {
    console.error('[pi3] Error in loadLesson().', e);
  }
}


async function loadQuiz(lessonId) {
  try {
    console.log('[pi3] Loading LearnerQuiz...', { lessonId });

    // get lesson/quiz data from server
    let lesson = await getLesson(lessonId);
    console.log('[pi3] Fetched quiz.', lesson);

    // get course progress from server
    let progress = await getCourseProgress(lesson.section.course.id);
    console.log('[pi3] Fetched progress.', progress);

    // render LearnerQuiz
    let container = document.getElementById('pi3quizReader');
    let reader = createRoot(container);
    reader.render(<LearnerQuiz lesson={lesson} progress={progress} />);

  }
  catch(e) {
    console.error('[pi3] Error in loadQuiz().', e);
  }
}

async function loadQuizPlus(lessonId) {
  try {
    console.log('[pi3] Loading LearnerQuizPlus...', { lessonId });

    // get lesson/quiz data from server
    let lesson = await getLesson(lessonId);
    console.log('[pi3] Fetched quiz.', lesson);

    // get course progress from server
    let progress = await getCourseProgress(lesson.section.course.id);
    console.log('[pi3] Fetched progress.', progress);

    // render LearnerQuiz
    let container = document.getElementById('pi3quizReader');
    let reader = createRoot(container);
    reader.render(<LearnerQuizPlus lesson={lesson} progress={progress} />);

  }
  catch(e) {
    console.error('[pi3] Error in loadQuizPlus().', e);
  }
}

async function loadChatflow(lessonId) {
  try {
    console.log('[pi3] Loading LearnerChatflow...', { lessonId });

    // get lesson/chatflow data from server
    let lesson = await getLesson(lessonId);
    console.log('[pi3] Fetched chatflow.', lesson);

    // get coursed progress from server
    let progress = await getCourseProgress(lesson.section.course.id);
    console.log('[pi3] Fetched progress.', progress);

    // render LearnerChatflow
    let container = document.getElementById('pi3lessonReader');
    let reader = createRoot(container);

    reader.render(<LearnerChatflow lesson={lesson} progress={progress} />);

  }
  catch(e) {
    console.error('[pi3] Error in loadChatflow().', e);
  }
}


async function loadCourseCertificate(courseId) {
  try {
    console.log('[pi3] Loading CourseCertificate...', { courseId });

    // // get course progress from server
    // let progress = await getCourseProgress(lesson.section.course.id);
    // console.log('[pi3] Fetched progress.', progress);

    // render CourseCertificate
    let container = document.getElementById('pi3courseCertificate');
    let reader = createRoot(container);

    // reader.render(<CourseCertificate progress={progress} />);
    reader.render(<CourseCertificate courseId={courseId} />);

  }
  catch(e) {
    console.error('[pi3] Error in loadCourseCertificate().', e);
  }
}

async function loadCourseCertificates() {
  console.log('[pi3] Loading CourseCertificates...');
  let container = document.getElementById('pi3courseCertificates');
  let root = createRoot(container);
  root.render(<CourseCertificates />);
}


function loadLogin() { 
  // console.log('[pi3] Hydrating Login...');
  // hydrateRoot(document.getElementById('loginContainer'), <Login />);
  console.log('[pi3] Rendering Login...');
  let root = createRoot(document.getElementById('loginContainer'));
  root.render(<Login />);
}

function loadContactUsPublic() {
  console.log('[pi3] Rendering ContactUsPublic...');
  let root = createRoot(document.getElementById('contactUsPublicContainer'));
  root.render(<ContactUsPublic />);
}

function loadContactUsLearners() {
  console.log('[pi3] Rendering ContactUsLearners...');
  let root = createRoot(document.getElementById('contactUsLearnersContainer'));
  root.render(<ContactUsLearners />);
}

function loadProfile() {
  console.log('[pi3] Rendering Profile...');
  let root = createRoot(document.getElementById('pi3profile'));
  root.render(<Profile />);
}

function loadLicense() {
  console.log('[pi3] Rendering License...');
  let root = createRoot(document.getElementById('pi3license'));
  root.render(<License />);
}

function loadCheckout() {
  console.log('[pi3] Rendering Checkout...');
  let root = createRoot(document.getElementById('pi3checkout'));
  root.render(<NewPurchaseFlow />);
}

function loadManagerDashboard() {
  console.log('[pi3] Rendering ManagerDashboard...');
  let root = createRoot(document.getElementById('pi3managerDashboard'));
  root.render(<ManagerDashboard />);
}


function loadJoin() {
  console.log('[pi3] Rendering Join...');
  let root = createRoot(document.getElementById('pi3join'));
  root.render(<Join />);
}

function loadRepurchase() {
  console.log('[pi3] Rendering Repurchase...');
  let root = createRoot(document.getElementById('repurchase'));
  root.render(<ConceptRepurchase />);
}

function loadCart() {
  try {
    console.log('[pi3] Loading Cart...');

    window.cart = new ConceptCart();

  }
  catch(e) {
    console.log('[pi3] Error performing loadCart.', e);
  }
}

async function logout() {
  try {
    console.log('[pi3] Performing logout()...');
    // empty cache from session
    await unsetUser();
    // run /api/logout request to logout the session server-side
    await ajax_logout();
  }
  catch(e) {
    console.log('[pi3] Error performing logout().', e);
  }
}

export const runClientApp = (path) => {
  const currentRoute = routes.filter(r => r.path == path)?.[0];
  if (!currentRoute)
    return;
  //hydrateRoot(document.getElementById('app')).render(React.createElement(currentRoute.reactComponent, {}));
  createRoot(document.getElementById('app')).render(React.createElement(currentRoute.reactComponent, {}));
};

window.pi3 = {
  loadMenu,
  loadLogin,
  loadCourseList,
  loadLesson,
  loadQuiz,
  loadQuizPlus,
  loadChatflow,
  loadCourseCertificate,
  loadCourseCertificates,
  loadContactUsPublic,
  loadContactUsLearners,
  loadProfile,
  loadLicense,
  loadCheckout,
  loadManagerDashboard,
  logout,
  loadJoin,
  loadRepurchase,
  loadCart,
};

window.runClientApp = runClientApp;
